import React from 'react'

import '../styles/Cursor.css'

const Cursor = () => {
      return(
            <div className="cursor">
                  <div></div>
            </div>
      )
}

export default Cursor